const BioHeadline = () => {
  return(
    <div>
      <div className="flex items-center space-x-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none" className="w-16 h-16">
          <path d="M16.1 48.5C15.6 48.4 15.2 48.3 14.7 48.1C14.2 47.9 13.8 47.8 13.4 47.6C12.5 47.2 11.7 46.7 10.9 46.1C9.30003 45 8.00003 43.5 7.00003 41.7C6.00003 40 5.40003 38 5.30003 36.1C5.20003 35.1 5.30003 34.2 5.40003 33.2C5.50003 32.7 5.60003 32.3 5.70003 31.8C5.80003 31.3 6.00003 30.9 6.10003 30.4L6.20003 31.8C6.20003 32.3 6.30003 32.7 6.40003 33.2C6.50003 34.1 6.70003 35 6.90003 35.8C7.30003 37.5 7.90003 39.1 8.80003 40.6C9.70003 42.1 10.7 43.5 12 44.8C12.6 45.4 13.3 46 14 46.6C14.3 46.9 14.7 47.2 15.1 47.5L16.1 48.5Z" fill="#14b8a6"/>
          <path d="M15.8 52.1C15.5 52.3 15.1 52.4 14.7 52.5C14.3 52.6 14 52.7 13.6 52.7C12.9 52.8 12.1 52.9 11.3 52.8C9.8 52.7 8.2 52.3 6.9 51.6C5.5 50.9 4.3 49.8 3.5 48.5C3.1 47.9 2.7 47.2 2.4 46.5C2.3 46.2 2.2 45.8 2.1 45.4C2.1 45.1 2 44.8 2 44.4C2.3 44.7 2.5 44.9 2.7 45.2C3 45.5 3.2 45.8 3.4 46C3.9 46.5 4.3 47 4.8 47.4C5.8 48.3 6.8 49.1 8 49.7C9.1 50.3 10.4 50.8 11.6 51.2C12.2 51.4 12.9 51.5 13.6 51.7C13.9 51.8 14.3 51.8 14.6 51.9C15.1 52 15.4 52 15.8 52.1" fill="#14b8a6"/>
          <path d="M38.4 3.5C38.9 3.6 39.3 3.8 39.8 4C40.3 4.2 40.7 4.4 41.1 4.6C42 5.1 42.8 5.6 43.6 6.2C45.2 7.4 46.5 9 47.4 10.8C48.3 12.6 48.8 14.6 48.8 16.5C48.8 17.5 48.7 18.4 48.5 19.4C48.4 19.9 48.3 20.3 48.1 20.8C47.9 21.3 47.8 21.7 47.6 22.1L47.5 20.7C47.5 20.2 47.5 19.8 47.4 19.3L47.1 16.6C46.8 14.9 46.2 13.2 45.4 11.6C44.6 10 43.6 8.6 42.4 7.3C41.8 6.6 41.1 6 40.5 5.3C40.2 5 39.8 4.7 39.4 4.4L38.4 3.5" fill="#14b8a6"/>
          <path d="M47.1 3.10001C47.5 3.20001 47.8 3.30001 48.1 3.50001C48.4 3.60001 48.8 3.80001 49.1 4.00001C49.7 4.40001 50.3 4.80001 50.9 5.30001C52 6.30001 52.9 7.50001 53.4 8.90001C54 10.3 54.2 11.8 54 13.3C53.9 14 53.7 14.7 53.5 15.4C53.4 15.7 53.2 16.1 53.1 16.4C52.9 16.7 52.8 17 52.5 17.3V15.3C52.5 14.6 52.4 14 52.4 13.4C52.2 12.1 52 10.9 51.5 9.80001C51 8.60001 50.4 7.60001 49.7 6.50001C49.3 6.00001 48.9 5.40001 48.5 4.90001C48.3 4.60001 48.1 4.40001 47.8 4.10001C47.6 3.60001 47.3 3.30001 47.1 3.10001" fill="#14b8a6"/>
          <path d="M10 18C8 18.9 7.29999 21.3 8.19999 23.3L20.8 49.6L27.8 46.3L15.2 19.9C14.3 17.9 12 17 10 18" fill="#FDDCC3"/>
          <path d="M43.1 38.9L50.5 35.4L36.1 5.40001C35.1 3.40001 32.7 2.50001 30.6 3.50001C28.6 4.50001 27.7 6.90001 28.7 9.00001L43.1 38.9Z" fill="#FDDCC3"/>
          <path d="M30.7 3.39999C30.5 3.49999 30.3 3.59999 30.1 3.79999C32 3.29999 34 4.19999 34.9 5.99999L49.3 36L50.6 35.4L36.2 5.39999C35.2 3.29999 32.8 2.39999 30.7 3.39999" fill="#E9C6AA"/>
          <path d="M27.8 46.2L35.5 42.5L20.8 11.9C19.8 9.79999 17.2 8.79999 15.1 9.79999C13 10.8 12.1 13.4 13.1 15.5L27.8 46.2Z" fill="#FDDCC3"/>
          <path d="M15.1 9.90001C14.9 10 14.7 10.1 14.5 10.3C16.4 9.80001 18.6 10.7 19.5 12.6L28.6 31.7L30.8 33L20.8 12C19.8 9.80001 17.3 8.90001 15.1 9.90001" fill="#E9C6AA"/>
          <path d="M34.3 40.1L42 36.4L27.3 5.8C26.3 3.7 23.7 2.7 21.6 3.8C19.5 4.8 18.6 7.4 19.6 9.5L34.3 40.1Z" fill="#FDDCC3"/>
          <path d="M21.6 3.69999C21.4 3.79999 21.2 3.99999 21 4.09999C22.9 3.59999 25.1 4.49999 26 6.39999L36.3 28L38.5 29.3L27.3 5.79999C26.3 3.59999 23.7 2.69999 21.6 3.69999" fill="#E9C6AA"/>
          <path d="M10 18C9.80002 18.1 9.60002 18.2 9.40002 18.4C11.2 17.9 13.1 18.8 13.9 20.6L21.4 36.3L23.6 37.6L15.2 20C14.3 17.9 12 17 10 18Z" fill="#E9C6AA"/>
          <path d="M60.8 15C58.1 12.9 53.7 15.2 51.5 22.4C50 27.4 49.8 28.9 46.6 30.4L44.8 26.7C44.8 26.7 16.4 40.4 17.5 42.6C17.5 42.6 20.9 53.2 26.7 58.1C35.3 65.5 55.4 57.6 56.3 38.5C56.8 27.4 63.7 17.3 60.8 15Z" fill="#FDDCC3"/>
          <path d="M60.8 15C60.3 14.6 59.7 14.4 59.1 14.3C59.2 14.4 59.4 14.4 59.5 14.5C62.5 16.8 59.4 22.1 57.7 26.9C56.3 30.7 55.1 34.6 55.3 38.4C56.1 55 39.4 62.9 29.4 59.9C39.2 64 57.4 56.2 56.6 38.9C56.4 35.1 57.5 31.4 59 27.4C60.6 22.6 63.7 17.3 60.8 15" fill="#E9C6AA"/>
          <path d="M47.5 30C41.3 30.7 32.2 39.6 38.6 49.3C33.9 39.5 41.6 32.9 46.5 30.6C47 30.2 47.5 30 47.5 30" fill="#E9C6AA"/>
        </svg>
        <h1 className="text-2xl font-extrabold leading-9 text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
          Hello, world!
        </h1>
      </div>
      <p className="text-gray-500">
        Personal portfolio of Simen F. Kristoffersen
      </p>
    </div>
  )
}

export default BioHeadline